var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "group_aside_task" }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("SidebarSearchGroup", {
          ref: "sidebar_search_group",
          staticClass: "sidebar_search_group",
        }),
        _vm.search_switch ? _c("group-aside-search") : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.search_switch,
                expression: "!search_switch",
              },
            ],
            staticClass: "default_status",
            attrs: { id: "default_status" },
          },
          [
            _vm._l(_vm.message_box_list, function (item, index) {
              return [
                item.chatType == 0
                  ? _c("ContactUnit", {
                      key: `message_box_list${item.chatId}_${index}`,
                      ref: `chatId${item.chatId}`,
                      refInFor: true,
                      staticClass: "cp un_sel",
                      attrs: { stick: "0", messageData: _vm.PMessageBox(item) },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.goSingleDialog(item)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "name",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.highlightKeywords(
                                          item.name || item.updateusername
                                        )
                                      ),
                                    },
                                  },
                                  [_vm._v("name1")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "desc",
                            fn: function () {
                              return [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.PMessageBox(item).viewMessageContent()
                                    ),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "time",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("universalDate")(
                                          item.lastSendTime,
                                          {
                                            formatStr: "MM月DD日",
                                            noTime: true,
                                          }
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
                item.chatType == 1
                  ? _c("WorkTeamUnit", {
                      key: `message_box_list${item.chatId}_${index}`,
                      ref: `chatId${item.chatId}`,
                      refInFor: true,
                      staticClass: "cp un_sel",
                      attrs: {
                        userlist: item.pictures,
                        stick: "0",
                        item: item,
                        messageData: _vm.PMessageBox(item),
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.goGroupDialog(item)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "name",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.highlightKeywords(item.name)
                                      ),
                                    },
                                  },
                                  [_vm._v("name1")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "desc",
                            fn: function () {
                              return [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.PMessageBox(item).viewMessageContent()
                                    ),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "time",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("universalDate")(
                                        item.lastSendTime,
                                        { formatStr: "MM月DD日", noTime: true }
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }